import axios from "axios";
import { getLocalAccessToken } from "./storage";
import { getBotCodeFromUrl } from ".";

const instance = axios.create({
  baseURL: `https://${import.meta.env.VITE_APP_DOMAIN}/ai/api/v1`,
});

instance.interceptors.request.use(
  (config) => {
    let botCode;
    if (config.params) {
      botCode = config.params.botCode as string | undefined;
      config.params.botCode = undefined;
    }
    if (!botCode) {
      botCode = getBotCodeFromUrl();
    }

    if (!botCode) {
      throw new Error("Not found botCode!");
    }

    const localAccessToken = getLocalAccessToken(botCode);
    if (localAccessToken) {
      config.headers["Authorization"] = `Bearer ${localAccessToken}`;
    }
    config.headers["Content-Type"] = "application/json;charset=UTF-8";
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (payload) => {
    // code为200
    const { data, headers, config } = payload;
    const traceId = headers["x-tp-trace-id"];
    if (traceId) {
      console.log("👉 TRACE_ID:", traceId, config.url);
    }
    return Promise.resolve(data);
  },
  (error) => {
    const res = error.response;

    // 用户信息失效，重定向到登录页面
    return Promise.reject(res?.data);
  }
);

export default instance;
