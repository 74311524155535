import Markdown, { MarkdownToJSX } from "markdown-to-jsx";
import { FunctionComponent } from "preact";
import { useBus } from "react-bus";

import { useRef, useState } from "preact/compat";
import { useCustomizeStore } from "~/stores";
import Category, { CategoryData } from "~/components/UIKit/Category";

import "./styles.scss";

interface IMdxTemplateMessageProps {
  text: string;
  done?: boolean;
  messageId: string;
}

const MdxTemplateMessage: FunctionComponent<IMdxTemplateMessageProps> = ({
  text,
  done,
  messageId,
}) => {
  const {
    customize: { brandColor, palettes },
  } = useCustomizeStore();
  const bus = useBus();
  const ref = useRef<HTMLDivElement>(null);
  const [checkedSet, setCheckedSet] = useState(new Set<string>());
  const checkedSetRef = useRef<Set<string>>(checkedSet);

  checkedSetRef.current = checkedSet;

  const handleUnCheckEvent = (event?: { messageId: string; data: CategoryData }) => {
    if (event?.messageId === messageId) {
      checkedSetRef.current.delete(event!.data.id);
      setCheckedSet(new Set(checkedSetRef.current));
    }
  };

  const handleClearAllEvent = () => {
    setCheckedSet(new Set());
  };

  const handleChange = (checkedEventName: string, uncheckedEventName: string) => {
    return (checked: boolean, data: CategoryData) => {
      if (checkedSetRef.current.size === 0 && checked) {
        bus.on("clearAll", handleClearAllEvent);
        bus.on("uncheckedFromEditor", handleUnCheckEvent);
      }

      if (checked) {
        checkedSetRef.current.add(data.id);
        bus.emit(checkedEventName, { messageId, data });
      } else {
        checkedSetRef.current.delete(data.id);
        bus.emit(uncheckedEventName, { messageId, data });
      }

      if (checkedSetRef.current.size === 0 && !checked) {
        bus.off("uncheckedFromEditor", handleUnCheckEvent);
      }

      setCheckedSet(new Set(checkedSetRef.current));
    };
  };

  const overrides: MarkdownToJSX.Overrides = {
    a: { component: (props) => <a {...props} target="_blank" /> },
    Category: {
      component: (props) => {
        return (
          <Category
            {...props}
            checked={checkedSetRef.current.has(props.id)}
            onChange={handleChange("categoryChecked", "categoryUnchecked")}
          />
        );
      },
    },
    Selection: {
      component: (props) => (
        <Category
          {...props}
          checked={checkedSetRef.current.has(props.id)}
          onChange={handleChange("selectionChecked", "selectionUnchecked")}
        />
      ),
    },
  };

  return (
    <div className="mdx-template-message-item" ref={ref}>
      <Markdown
        className="markdown-body is-bot"
        options={{
          wrapper: "div",
          forceWrapper: true,
          overrides,
        }}
      >
        {text}
      </Markdown>
    </div>
  );
};

export default MdxTemplateMessage;
