import classNames from "classnames";
import { FunctionComponent } from "preact";
import CheckSVG from "~/assets/icons/check.svg?react";

import "./styles.scss";

export interface CategoryData {
  id: string;
  name: string;
  parent?: string;
}

export interface ICategoryProps {
  id: string;
  name: string;
  parent: string;
  checked: boolean;

  onChange?: (checked: boolean, data: CategoryData) => void;
}

const Category: FunctionComponent<ICategoryProps> = ({
  id,
  name,
  parent,
  checked = false,
  onChange,
}) => {
  return (
    <span
      className={classNames("category", { checked })}
      onClick={() => {
        onChange?.(!checked, { id, name, parent });
      }}
    >
      <span className="inner">{checked && <CheckSVG />}</span>

      <span className="text">{name}</span>
    </span>
  );
};

export default Category;
