import { useParams } from "wouter-preact";
import Bot from "~/components/Bot";
import { useEffect } from "preact/hooks";
import { setLocalAccessToken } from "~/utils/storage";

export default function BotPage() {
  const { botCode } = useParams();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const origin = params.get("origin");
    // opened from third party website
    if (origin && window.opener) {
      (window.opener as Window).postMessage({ type: "target-pilot-bot-rendered", origin }, origin);
      window.addEventListener("message", (e: MessageEvent) => {
        const {
          data: { type, code, token },
          origin: msgOrigin,
        } = e;
        if (
          origin === msgOrigin &&
          type === "target-pilot-bot-token" &&
          code === botCode &&
          token
        ) {
          setLocalAccessToken(code, token);
          (window.opener as Window).postMessage(
            { type: "target-pilot-bot-token-settled", code, origin },
            origin
          );
          setTimeout(() => window.location.reload(), 50);
        }
      });
    }
  }, []);

  if (!botCode) return null;

  return <Bot code={botCode as string} />;
}
