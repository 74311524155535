import classNames from "classnames";
import PReact, { useRef } from "preact/compat";
import { Message, MessageType, ProductCompareItem } from "~/types";
import Feedback from "./Feedback";
import LoadingMessage from "./LoadingMessage";
import MessageItemErrorBoundary from "./MessageErrorBoundary";
import ProductCompareMessage from "./ProductCompareMessage";
import ProductSearchMessage from "./ProductSearchMessage";
import TextMessage from "./TextMessage";

import ProductComparision from "./ProductComparision";
import RecommendQuestions from "./RecommendQuestions";
import MessageCreatedTime from "./Time";
// import VideoMessage from "./VideoMessage";
import ContactCard from "./ContactCard";
import ExpressCard from "./ExpressCard";
import MdxTemplate from "./MdxTemplate";
import ProductGroup from "./ProductGroup";

import "./index.scss";
import "./markdown.scss";

interface MessageItemProps {
  avatar: string;
  message: Message;
  sendMessage: (text: string) => void;
  sendTextMessage: (text: string) => void;
}

const MessageItem: PReact.FC<MessageItemProps> = ({
  avatar,
  message,
  sendMessage,
  sendTextMessage,
}) => {
  const {
    type,
    text,
    done,
    isBot,
    subType,
    payload,
    resource,
    loadingSteps,
    createdAt,
    compareData,
    metadata,
    messageId,
  } = message;
  const renderItemDOMRef = useRef<HTMLDivElement>(null);

  const boxClassname = classNames("message-box", `${type}-type`, {
    bot: isBot,
    user: !isBot,
  });

  const blockClassname = classNames("block", `${type}-type`, {
    bot: isBot,
    user: !isBot,
  });

  const showAvatarBool = isBot && type !== MessageType.QUESTION_RECOMMENDR;

  const renderMessage = () => {
    switch (type) {
      case MessageType.SEND_WAITING:
      case MessageType.LOADING_STEP:
        return <LoadingMessage data={loadingSteps as string[]} />;

      case MessageType.TEXT:
      case MessageType.NO_CREDIT:
      case MessageType.UNAVAILABLE:
      case MessageType.KNOWLEDGE_SEARCH:
      case MessageType.CONTENT_FILTERED:
      case MessageType.RATE_LIMIT_EXCEEDED:
        return text?.trim() ? (
          <TextMessage text={text as string} isBot={isBot} done={done} />
        ) : null;

      case MessageType.QUESTION_RECOMMENDR:
        return (
          <RecommendQuestions
            recommendQuestions={payload ?? []}
            sendTextMessage={sendTextMessage}
          />
        );

      case MessageType.PRODUCT_SEARCH:
        return (
          <ProductSearchMessage
            text={text as string}
            data={resource?.ProductSearch}
            isHistory={message.isHistory}
          />
        );

      case MessageType.PRODUCT_RECOMMENDER:
        return (
          <ProductSearchMessage
            text={text as string}
            data={resource?.ProductRecommender}
            isHistory={message.isHistory}
          />
        );
      case MessageType.PRODUCT_COMPARE:
        return <ProductCompareMessage text={text as string} data={resource?.ProductCompare} />;
      case MessageType.INSTRUCTION_COMPARE:
        return <ProductComparision text={text} compareData={compareData as ProductCompareItem[]} />;
      // case MessageType.VIDEO:
      //   return <VideoMessage message={message} />;

      case MessageType.CONTACT:
        return <ContactCard />;

      case MessageType.PACKAGE_TRACKING:
        return <ExpressCard packageTracking={metadata.packageTracking} />;

      case MessageType.MDX_TEMPLATE:
        return <MdxTemplate text={text!} done={done} messageId={messageId!} />;

      case MessageType.PRODUCT_GROUPS:
        return <ProductGroup productGroups={resource?.ProductGroups} />;
    }
  };

  // disable video type msg temporarily
  if (type === MessageType.VIDEO) {
    return null;
  }
  // if (type === MessageType.VIDEO && getVideoPopuped() === "true") return null; // original logic

  return (
    <div className={boxClassname}>
      <div className={blockClassname}>
        <div className="wrapper-item">
          {showAvatarBool && <img src={avatar} alt="" className="avatar" />}
          <MessageItemErrorBoundary>
            <div className={`message-item-render ${type}-type`} ref={renderItemDOMRef}>
              {renderMessage()}

              {/* 时间显示 */}
              <MessageCreatedTime
                isBot={isBot}
                time={createdAt as unknown as string}
                type={type}
                hoverRef={renderItemDOMRef}
              />

              <Feedback
                type={type}
                subType={subType}
                isBot={isBot}
                hoverRef={renderItemDOMRef}
                sendMessage={sendMessage}
                message={message}
              />
            </div>
          </MessageItemErrorBoundary>
        </div>
      </div>

      {/* 分割线 */}
      {/* {type === MessageType.DIVIDER && <DeviderMessage />} */}
    </div>
  );
};

export default MessageItem;
