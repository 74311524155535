import { Route, Switch } from "wouter-preact";
import BotPage from "./page/Bot";
import BotIntegration from "./page/BotIntegration";

function App() {
  return (
    <Switch>
      <Route path="/:botCode">
        <BotPage />
      </Route>
      {import.meta.env.DEV && (
        <>
          <Route path="/demo/:botCode">
            <BotIntegration />
          </Route>
        </>
      )}
    </Switch>
  );
}

export default App;
