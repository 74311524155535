import { useDebounceFn } from "ahooks";
import { RefObject } from "preact";
import { useEffect, useRef } from "preact/hooks";
import { useBus } from "react-bus";
import ContentEditable from "react-contenteditable";
import { CategoryData } from "../UIKit/Category";
import { useCustomizeStore, useMessageStore } from "~/stores";
import "./Category";

export function useCategoryInEditor(editorDomRef: RefObject<ContentEditable>) {
  const bus = useBus();
  const {
    customize: { palettes },
  } = useCustomizeStore();
  const { checkedCategory, addCheckedCategory, deleteCheckedCategory } = useMessageStore();

  const manualClickClose = useRef("");
  const messageIds = useRef<Set<string>>(new Set<string>());

  const createCategory = (messageId: string, id: string, name: string, parent?: string) => {
    messageIds.current.add(messageId);
    const category = document.createElement("tp-category");
    category.setAttribute("messageId", messageId);
    category.setAttribute("id", id);
    category.setAttribute("name", name);
    category.setAttribute("palettes", JSON.stringify(palettes));
    if (parent) {
      category.setAttribute("parent", parent);
    }
    // need to make tag not blank to trigger custom rules in turndown
    category.appendChild(document.createTextNode("|"));
    category.contentEditable = "false";
    category.onclick = (e) => {
      e.stopPropagation();
      const selection = window.getSelection();
      if (selection) {
        const range = document.createRange();
        range.selectNode(e.target as Node);
        range.collapse(false);
        selection.removeAllRanges();
        selection.addRange(range);
      }
    };
    return category;
  };

  const addCategory = (event?: { messageId: string; data: CategoryData }) => {
    console.log("addCategory event: ", event);
    if (!event) return;
    const { data, messageId } = event;
    const { id, name, parent } = data;
    addCheckedCategory(data);
    const editorDiv = editorDomRef.current?.el?.current as HTMLDivElement;
    editorDiv.focus();
    const selection = window.getSelection();
    if (selection && id && name) {
      const categoryNode = createCategory(messageId, id, name, parent);
      const textNode = document.createTextNode("\ufeff");
      const range = document.createRange();
      range.selectNodeContents(editorDiv);
      range.collapse(false);
      range.insertNode(categoryNode);
      range.insertNode(textNode);
      const newRange = document.createRange();
      newRange.selectNodeContents(editorDiv);
      newRange.setStartAfter(textNode);
      newRange.collapse(false);
      selection.removeAllRanges();
      selection.addRange(newRange);
    }
  };
  const removeCategory = (event?: { messageId: string; data: CategoryData }) => {
    console.log("removeCategory event: ", event);
    if (!event) return;
    const { data, messageId } = event;
    const { id } = data;
    deleteCheckedCategory(id);
    const selection = window.getSelection();
    if (selection && id) {
      const selection = window.getSelection();
      if (selection) {
        try {
          const range = selection.getRangeAt(0);
          const categoryNode = document.querySelector(
            `tp-category#${id}[messageid='${messageId}']`
          );
          if (categoryNode) {
            range.selectNode(categoryNode);
            range.deleteContents();
            range.collapse(false);
          }
        } catch (error) {
          //
        }
      }
    }
  };

  const clearCategory = () => {
    Object.keys(checkedCategory).forEach((id) => {
      messageIds.current.forEach((messageId) => {
        console.log("uncheckedFromEditor: ", { messageId, data: checkedCategory[id] });
        bus.emit("uncheckedFromEditor", { messageId, data: checkedCategory[id] });
      });
    });
  };

  const { run: emitCategoryUnchecked } = useDebounceFn(
    (messageId: string, id: string, name: string, parent?: string) => {
      console.log("uncheckedFromEditor: ", { messageId, data: { id, name, parent } });
      bus.emit("uncheckedFromEditor", { messageId, data: { id, name, parent } });
      removeCategory({ messageId, data: { id, name, parent } });
      deleteCheckedCategory(id);
    },
    { wait: 100 }
  );
  useEffect(() => {
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.type === "childList") {
          mutation.removedNodes.forEach((node) => {
            if (node.nodeName === "TP-CATEGORY") {
              const messageId = (node as Element).getAttribute("messageid") || "";
              const id = (node as Element).getAttribute("id") || "";
              const name = (node as Element).getAttribute("name") || "";
              const parent = (node as Element).getAttribute("parent") || "";
              if (manualClickClose.current === id) {
                manualClickClose.current = "";
                return;
              }
              console.log("backspace remove node: ", node);
              emitCategoryUnchecked(messageId, id, name, parent);
            }
          });
        }
      });
    });
    if (editorDomRef.current?.el?.current) {
      observer.observe(editorDomRef.current?.el?.current, { childList: true, subtree: true });
    }

    return () => {
      if (editorDomRef.current?.el?.current) {
        observer.disconnect();
      }
    };
  }, [editorDomRef.current?.el?.current]);

  const closeCategory = ({ detail }: CustomEvent<CategoryData & { messageId: string }>) => {
    const { messageId, id, name, parent } = detail;
    manualClickClose.current = id;
    emitCategoryUnchecked(messageId, id, name, parent);
  };

  useEffect(() => {
    // @ts-expect-error custom event
    document.addEventListener("tp-category-close", closeCategory);
    bus.on("categoryChecked", addCategory);
    bus.on("categoryUnchecked", removeCategory);
    return () => {
      bus.off("categoryChecked", addCategory);
      bus.off("categoryUnchecked", removeCategory);
      // @ts-expect-error custom event
      document.removeEventListener("tp-category-close", closeCategory);
    };
  }, [bus]);

  return { clearCategory };
}
