import { RefObject, useCallback, useRef } from "preact/compat";
import { getLocalMessageId } from "~/utils";
import { useCustomizeStore, useMessageStore } from "~/stores";
import {
  BotServiceStatus,
  Message,
  MessageItem,
  MessageState,
  MessageType,
  SegmentMessage,
} from "~/types";
import useChat, { ChatType } from "./chat";
import useHistory from "./history";
import useScroll from "../useScroll";

interface HookProps {
  tenantId: string;
  messageListDOMRef?: RefObject<HTMLDivElement>;
}

// 当前正在接收的 runId
let currentReceiveRunId: string = "";

// 点击停止按钮时，需要停止当前的 runId 的消息渲染
let currentStopRunId: string = "";

const useMessage = ({ messageListDOMRef }: HookProps) => {
  const eventSourceRef = useRef<EventSource>();
  const {
    messages,
    appendMessage,
    removePendingMessage,
    updateMessage,
    messageState,
    setMessageState,
    markLatestMessageDone,
  } = useMessageStore();
  const { ifHasMoreMessage, fetchMoreMessages, fetchHistoryPending } = useHistory({
    scrollDOMRef: messageListDOMRef as RefObject<HTMLDivElement>,
  });

  const messagesRef = useRef(messages);
  messagesRef.current = messages;
  const state = useRef(messageState);
  state.current = messageState;

  const {
    customize: { serviceStatus },
  } = useCustomizeStore();

  const startChatCall = useChat({ pipeMessage });

  async function sendMessage(text: string) {
    if (serviceStatus === BotServiceStatus.INACTIVE) {
      let message: Message = {
        type: MessageType.UNAVAILABLE,
        text: "Sorry, the bot is out of service, please contact the owner of the Chatbot to get it into service",
        isBot: true,
        createdAt: new Date(),
        messageId: getLocalMessageId(),
        done: true,
      };

      removePendingMessage();
      setMessageState(MessageState.AVAILABLE);
      appendMessage(message);
      return Promise.resolve();
    }

    return new Promise((resolve, reject) => {
      startChatCall({
        text,
        onSuccess: resolve,
        onError: reject,
        markDone: markLatestMessageDone,
      });
    });
  }

  async function sendComparisionMessage() {
    return new Promise((resolve, reject) => {
      startChatCall({
        type: ChatType.COMPARASION,
        onSuccess: resolve,
        onError: reject,
        markDone: markLatestMessageDone,
      });
    });
  }

  function pipeMessage(payload: MessageItem) {
    const messages = messagesRef.current;
    let { text, type, done, id: messageId, sequenceNumber, metadata, loadingSteps } = payload;
    currentReceiveRunId = messageId;
    let resource = metadata?.resource;

    if (currentStopRunId && currentReceiveRunId && currentStopRunId === currentReceiveRunId) {
      console.log("当前 runId 已经停止渲染");
      return;
    }

    const currentProcessingMessage = messages.find((item: any) => item.messageId === messageId);
    const isNewMessage =
      !currentProcessingMessage ||
      type === MessageType.NO_CREDIT ||
      type === MessageType.CONTENT_FILTERED;

    const isPendingType = [MessageType.SEND_WAITING, MessageType.LOADING_STEP].includes(type);
    if (isPendingType) {
      removePendingMessage();
      setMessageState(MessageState.AVAILABLE);
    }

    if (type === MessageType.ONLY_CHAT || type === MessageType.KNOWLEDGE_SEARCH) {
      type = MessageType.TEXT;
    }

    // 针对业务扩展类型消息的乱序问题，需要调整其 type 类型，当 partial_answer 晚于 answer 时，需要将 type 纠正
    if (!isNewMessage && !done && !isPendingType) {
      resource = currentProcessingMessage?.resource;
      // type = currentProcessingMessage?.type as MessageType;
    }

    // FIXME:是否可以用 isNewMssage 来替代这里的判断条件
    if (
      type === MessageType.NO_CREDIT ||
      type === MessageType.CONTENT_FILTERED ||
      type === MessageType.RATE_LIMIT_EXCEEDED
    ) {
      removePendingMessage();
    }

    let message: Message = {
      type,
      text,
      isBot: true,
      createdAt: new Date(),
      messageId,
      loadingSteps: [],
      done,
      resource,
    };

    if (type === MessageType.QUESTION_RECOMMENDR) {
      message.payload = metadata?.questionRec;
    }

    // 对于手动商品对比的卡片消息
    if (type === MessageType.INSTRUCTION_COMPARE) {
      message.compareData = resource?.InstructionCompare ?? [];
    }

    // 针对 loading-step 的消息类型，重新整合
    if (loadingSteps) {
      const temp: string[] = ([] as string[])
        .concat(currentProcessingMessage?.loadingSteps as string[])
        .concat(loadingSteps as unknown[] as string[])
        .filter((item) => item !== undefined);
      message.loadingSteps = temp;
      removePendingMessage();
      updateMessage(message);
    }

    // 针对卡片业务消息的 Streaming 渲染
    function renderMessage() {
      if (isNewMessage && payload.type !== MessageType.SEND_WAITING) {
        appendMessage(message);
      }

      if (done) {
        if (currentProcessingMessage?.segments) {
          message.segments = currentProcessingMessage.segments;
        }
        updateMessage(message);
      } else {
        renderParticialMessage(message);
      }
    }

    // 分段消息的渲染
    function renderParticialMessage(message: Message) {
      const segmentMessage: SegmentMessage = {
        sequenceNumber: sequenceNumber as number,
        text: text as string,
      };

      if (isNewMessage) {
        message.segments = [segmentMessage];
      } else {
        // 将 segmentMessage 插入 message.segments 时，每次都要根据 sequenceNumber 进行重排
        message.segments = ((currentProcessingMessage as Message).segments as SegmentMessage[])
          .concat(segmentMessage)
          .sort((a, b) => a.sequenceNumber - b.sequenceNumber);

        message.text = message.segments.map((item) => item.text).join("");

        updateMessage(message);
      }
    }

    renderMessage();

    setTimeout(scrollToBottom);
  }

  const { scrollToBottom } = useScroll({
    scrollContainerRef: messageListDOMRef,
    // scrollToTopHandler: fetchMoreMessages,
    processing: fetchHistoryPending,
  });

  // 发送文本消息
  async function sendTextMessage(text: string) {
    if (state.current !== MessageState.AVAILABLE) return;

    const localMessage: Message = {
      type: MessageType.TEXT,
      text,
      isBot: false,
      createdAt: new Date(),
      messageId: getLocalMessageId(),
    };

    appendMessage(localMessage);

    const waitingMessage: Message = {
      messageId: getLocalMessageId(),
      type: MessageType.SEND_WAITING,
      isBot: true,
    };

    appendMessage(waitingMessage);

    setMessageState(MessageState.SENDING);
    setTimeout(scrollToBottom);
    await sendMessage(text);
  }

  const stopReceive = useCallback(() => {
    eventSourceRef.current?.close();
    setMessageState(MessageState.AVAILABLE);
    currentStopRunId = currentReceiveRunId;
    removePendingMessage();
    // TODO:禁止后续渲染
  }, [eventSourceRef.current]);

  return {
    messageState: state.current,
    messageList: messagesRef.current,
    sendTextMessage,
    sendMessage,
    stopReceive,
    ifHasMoreMessage,
    fetchHistoryPending,
    fetchMoreMessages,
    scrollToBottom,
    sendComparisionMessage,
  };
};

export default useMessage;
