import { useEffect, useState } from "preact/hooks";
import register from "~/BotWidget/Preact2WC";
import Icon from "@ant-design/icons";
import closeSVG from "~/assets/icons/close.svg?react";
import { useDebounceFn } from "ahooks";

const Category = ({
  messageid,
  id,
  name,
  parent,
  palettes,
}: {
  messageid: string;
  id: string;
  name: string;
  parent?: string;
  palettes?: string; // string[]
}) => {
  const [hoverColor, setHoverColor] = useState("#beefe7");
  const [closeBgColor, setCloseBgColor] = useState("#17cfb1");
  const [closeColor, setCloseColor] = useState("#00846f");
  const [hover, setHover] = useState(false);

  useEffect(() => {
    try {
      const paletteColors = JSON.parse(palettes || "[]") as string[];
      if (paletteColors[1]) {
        setHoverColor(paletteColors[1]);
      }
      if (paletteColors[3]) {
        setCloseBgColor(paletteColors[3]);
      }
      if (paletteColors[6]) {
        setCloseColor(paletteColors[6]);
      }
    } catch (error) {
      //
    }
  }, [palettes]);

  const { run: setHoverFalse, cancel: cancelHoverFalse } = useDebounceFn(() => setHover(false), {
    wait: 100,
  });
  const onMouseOver = () => {
    cancelHoverFalse();
    setHover(true);
  };
  const onMouseOut = () => {
    cancelHoverFalse();
    setHoverFalse();
  };

  return (
    <div
      style={{
        display: "inline-flex",
        alignItems: "center",
        backgroundColor: hover ? hoverColor : "transparent",
        borderRadius: "4px",
        padding: "0 4px",
      }}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
    >
      <span style={{ marginRight: hover ? "4px" : 0 }}>{`${name}`}</span>
      {hover && (
        <div
          style={{
            display: "inline-flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
            backgroundColor: closeBgColor,
            color: closeColor,
            width: "16px",
            height: "16px",
            borderRadius: "4px",
          }}
          onMouseOver={onMouseOver}
          onMouseOut={onMouseOut}
          onClick={(e) => {
            e.stopPropagation();
            document.dispatchEvent(
              new CustomEvent("tp-category-close", {
                bubbles: true,
                cancelable: true,
                detail: { messageId: messageid, id, name, parent },
              })
            );
          }}
        >
          <Icon component={closeSVG} />
        </div>
      )}
    </div>
  );
};

register(Category, "tp-category", ["messageid", "id", "name", "parent", "palettes"], {
  shadow: true,
  mode: "open",
});
