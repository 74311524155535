import { render } from "preact/compat";
import reportWebVitals from "./reportWebVitals";
import App from "./App";

import "./utils/tracker";

import "./styles/reset.css";
import "./styles/index.scss";

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

render(<App />, document.getElementById("root") as HTMLElement);
