import { register } from "@shopify/web-pixels-extension";

try {
  register(({ analytics }) => {
    analytics.subscribe("page_viewed", async (event) => {
      console.log("page_viewed: ", event);
    });
  });
} catch (error) {
  console.warn(error); // TODO: log errors
}
